var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('h1',[_vm._v("Be like Torn, annoying")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],style:({
      height: '150px',
      width: '100%',
      maxWidth: '490px',
      minWidth: '100px'
    }),attrs:{"playerholder":"Input","maxlength":_vm.nitro ? _vm.max / 2.5 : _vm.max / 5},domProps:{"value":(_vm.input)},on:{"input":function($event){if($event.target.composing){ return; }_vm.input=$event.target.value}}}),_c('div',{style:({
    display: 'flex',
    width: '500px',
    marginLeft: 'auto',
    marginRight: 'auto'
  })},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nitro),expression:"nitro"}],attrs:{"type":"checkbox","id":"nitro1"},domProps:{"checked":Array.isArray(_vm.nitro)?_vm._i(_vm.nitro,null)>-1:(_vm.nitro)},on:{"change":function($event){var $$a=_vm.nitro,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.nitro=$$a.concat([$$v]))}else{$$i>-1&&(_vm.nitro=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.nitro=$$c}}}}),_c('label',{attrs:{"for":"nitro1"}},[_vm._v("Nitro?")])]),_c('div',{style:({
      marginLeft: 'auto'
    })}),_c('div',[_vm._v(_vm._s(_vm.input.length)+"/"+_vm._s(_vm.nitro ? _vm.max / 2.5 : _vm.max / 5))])]),_c('br'),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.output),expression:"output"}],style:({
      height: '150px',
      width: '100%',
      maxWidth: '490px',
      minWidth: '100px',
    }),attrs:{"playerholder":"Output","readonly":""},domProps:{"value":(_vm.output)},on:{"input":function($event){if($event.target.composing){ return; }_vm.output=$event.target.value}}}),_c('div',{style:({
    display: 'flex',
    width: '500px',
    marginLeft: 'auto',
    marginRight: 'auto'
  })},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nitro),expression:"nitro"}],attrs:{"type":"checkbox","id":"nitro2"},domProps:{"checked":Array.isArray(_vm.nitro)?_vm._i(_vm.nitro,null)>-1:(_vm.nitro)},on:{"change":function($event){var $$a=_vm.nitro,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.nitro=$$a.concat([$$v]))}else{$$i>-1&&(_vm.nitro=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.nitro=$$c}}}}),_c('label',{attrs:{"for":"nitro2"}},[_vm._v("Nitro?")])]),_c('div',{style:({
      marginLeft: 'auto'
    })}),_c('div',[_vm._v(_vm._s(_vm.output.length)+"/"+_vm._s(_vm.nitro ? _vm.max * 2 : _vm.max))])])])}
var staticRenderFns = []

export { render, staticRenderFns }